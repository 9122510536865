<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <role-category-form :role-category="roleCategory">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </role-category-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import RoleCategoryForm from "../../../components/forms/role-category-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";

export default {
    components: {CallbackButton, RoleCategoryForm},

    computed: {
        ...mapGetters({
            roleCategory: 'roleCategory/item',
            categories: 'roleCategory/all'
        }),

        title: function () {
            return this.roleCategory.id ? this.$tc('authorization::role_categories.role_category', 2).ucFirst() + ' / ' + this.roleCategory.name : this.$t('base.create_new_item', {item: this.$tc('authorization::role_categories.role_category', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/authorization/role-categories')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (!this.categories || !this.categories.length) {
            this.$store.dispatch('roleCategory/all');
        }
        if (this.$route.params.id) {
            this.$store.dispatch('roleCategory/show', this.$route.params.id)
        }
    },

    unmounted() {
        this.$store.dispatch('roleCategory/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
